import { Typography } from '@mui/material'
import React from 'react'
import Development from './Development';
import Internship from './Internship';
import Training from './Training';

function OurServicesPage() {

    const heading = {
        color: "teal",
        backgroundImage: `url('./assets/bg.png')`,
        backgroundSize: "100%",
        height: "350px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "3%",
        // background: "linear-gradient(to right, white, teal)",
    };

    return (
        <div>
            <div style={heading}>
                <Typography variant="h1" fontWeight="bold">
                    OUR SERVICES
                </Typography>
            </div>

            <Development />
            <Internship />
            <Training />
        </div>
    )
}

export default OurServicesPage