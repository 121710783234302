import "./App.css";
import Navbar from "./Pages/Header/Navbar";
import Footer from "./Pages/Footer/Footer";
import Body from "./Pages/Body/Body";

function App() {
  return (
    <>
      <Navbar />
      <Body />
      <Footer />

      {/* <AboutUsPage />
      <OurValues /> 
      <OurTeam /> */}

      {/* <Development />
      <Training />
      <Internship /> */}
    </>
  );
}

export default App;
