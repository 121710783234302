import React from "react"; 
import data from "../careers_list.json"
import { Button, Stack, Typography } from "@mui/material";

function PersonalAssistant({ path }) {

    const main = {
        margin: '0 15% 2% 15%'
    }

    const heading = {
        margin: '2% 0 0% 0'
    }

    const button = {
        backgroundColor: 'lightblue',
        color: 'black',
        borderRadius: '20px',
        border: '1px solid black'
    }

    const careers = data.careers_list.filter( item => item.url === path)
    const career = careers[0]

    return (
        <div style={main}>
            <Stack spacing={2} style={{ marginBottom: '1.5%' }} >
                <Typography variant='h4' fontWeight='bold' style={heading} >{career.title}</Typography>

                <Stack direction='row' spacing={4} >
                    <Typography>Experience: {career.experience}</Typography>
                    <Typography>Salary: {career.salary}</Typography>
                </Stack>

                <Stack direction='row' spacing={4} >
                    <Typography>Job Type: {career.type}</Typography>
                    <Typography>Location: {career.location}</Typography>
                </Stack>
                        
                <Stack direction='row' spacing={4} alignItems='center' >
                    <Button variant='contained' href="#" style={button} >Apply Now</Button>
                    <Typography>Last Date to Apply: {career.lastDate}</Typography>
                </Stack>

                <Typography variant='h5' fontWeight='bold' style={heading} >JOB DESCRIPTION</Typography>
                <Typography variant='h5' fontWeight='bold' >{career.title}</Typography>
                <Typography fontWeight='bold' variant='h6' >The primary role and responsibility of this position will be:</Typography>

                <ul>
                    <li>
                        <Typography><b>Diary Management</b> - Provide support to the project team members on maintaining and managing key project meetings and the meeting location.</Typography>
                    </li>
                    <li>
                        <Typography><b>Calendar Management</b> - Maintaining project team’s calendar to showcase all key events that needs to be planned.</Typography>
                    </li>
                    <li>
                        <Typography><b>Meetings Management</b> - Working closely with the project teams and other stakeholders in scheduling/organizing the meeting or conference call.</Typography>
                    </li>
                    <li>
                        <Typography><b>Travel Management</b> - Support the project team members in booking the travel ticket.</Typography>
                    </li>
                    <li>
                        <Typography><b>Documentation</b> - Recording the minutes of the meeting with clear emphasis on the key action points from the meeting. Maintaining the project database and eRoom to ensure all documentations are captured and organized for ease of access.</Typography>
                    </li>
                    <li>
                        <Typography><b>Transcription</b> - Business Transcription of recorded meetings, events, conference, etc.</Typography>
                    </li>

                </ul>

                <Typography variant='h6' fontWeight='bold' >Skills Required:</Typography>
                <ul>
                    <li>
                        <Typography>Excellent written and oral communication skills</Typography>
                    </li>
                    <li>
                        <Typography>A strong team player who is comfortable working collaboratively with others</Typography>
                    </li>
                    <li>
                        <Typography>Must be able to work virtually, and independently</Typography>
                    </li>
                    <li>
                        <Typography>Able to be diplomatic when dealing with clients</Typography>
                    </li>
                    <li>
                        <Typography>Respond well to deadlines and work outside of normal hours when required</Typography>
                    </li>
                    <li>
                        <Typography>Able to work in a rapidly changing environment and prioritize accordingly</Typography>
                    </li>
                    <li>
                        <Typography>Flexibility to relocate for project deployment to any part of India and work in 24X7 shifts basis business requirement</Typography>
                    </li>
                </ul>

                <Typography variant='h5' fontWeight='bold' style={heading} >ELIGIBILITY CRITERIA</Typography>

                <ul>
                    <li>
                        <Typography>Bachelor's Degree(BBA & B.Com. only)</Typography>
                    </li>
                    <li>
                        <Typography>Only for girl Candidate</Typography>
                    </li>
                    <li>
                        <Typography>Age of candidate should not be above 23 years</Typography>
                    </li>
                    <li>
                        <Typography>No active backlogs during onboarding</Typography>
                    </li>
                    <li>
                        <Typography>Maximum 1-year gap in education (post 10th standard till completion of graduation / Post-graduation)</Typography>
                    </li>
                    <li>
                        <Typography>Excellent verbal and written language skills in English and Hindi with the ability to tailor messages for phone or email conversations</Typography>
                    </li>
                    <li>
                        <Typography>Ability stay focused under pressure and manage urgent tasks against aggressive timelines</Typography>
                    </li>
                    <li>
                        <Typography>Maturity to handle confidential and/or sensitive information</Typography>
                    </li>
                    <li>
                        <Typography>Candidates must be eligible to work in India either by citizenship/ have relevant work permit documents</Typography>
                    </li>
                </ul>

                <Typography variant='h5' fontWeight='bold' style={heading} >PROCESS</Typography>
                <Typography variant='h6' fontWeight='bold' >Assessment Process:</Typography>
                <ul>
                    <li>
                        <Typography>If you are eligible, you will receive an email invitation to attend online assessments</Typography>
                    </li>
                    <li>
                        <Typography>The assessment is scheduled in two stages and must be completed within the scheduled time</Typography>
                    </li>
                </ul>

                <Typography fontWeight='bold' variant='h6' >Stage 1:</Typography>
                <ul>
                    <li>
                        <Typography>HR Interview</Typography>
                    </li>
                </ul>

                <Typography fontWeight='bold' variant='h6' >Stage 2:</Typography>
                <ul>
                    <li>
                        <Typography>CEO Interview</Typography>
                    </li>
                </ul>

            </Stack>
            <Button variant='contained' href="#" style={button} >Apply Now</Button>
        </div>
    )
}

export default PersonalAssistant