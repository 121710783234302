import React, { useMemo, useState } from "react";
import data from "../careers_list.json"
import { Button, List, ListItem, ListItemIcon, Stack, Typography } from "@mui/material";


function InternPython({ path }) {

    const main = {
        margin: '0 15% 2% 15%'
    }

    const heading = {
        margin: '2% 0 0% 0'
    }

    const button = {
        backgroundColor: 'lightblue',
        color: 'black',
        borderRadius: '20px',
        border: '1px solid black'
    }

    const careers = data.careers_list.filter(item => item.url === path)
    const career = careers[0]

    return (
        <div style={main}>
            <Stack spacing={2} style={{ marginBottom: '1.5%' }} >
                <Typography variant='h4' fontWeight='bold' style={heading} >{career.title}</Typography>

                <Stack direction='row' spacing={4} >
                    <Typography>Experience: {career.experience}</Typography>
                    <Typography>Stipend: {career.salary}</Typography>
                </Stack>

                <Stack direction='row' spacing={4} >
                    <Typography>Job Type: {career.type}</Typography>
                    <Typography>Location: {career.location}</Typography>
                </Stack>

                <Stack direction='row' spacing={4} alignItems='center' >
                    <Button variant='contained' href="#" target="_blank" style={button} >Apply Now</Button>
                    <Typography>Last Date to Apply: {career.lastDate}</Typography>
                </Stack>

                <Typography variant='h5' fontWeight='bold' style={heading} >JOB DESCRIPTION</Typography>
                <Typography variant='h5' fontWeight='bold' >{career.title}</Typography>
                <Typography fontWeight='bold' variant='h6' >As an intern you are responsible to:</Typography>

                <ul>
                    <li>
                        <Typography>Design, build, test, assemble, support and configure application using business requirements</Typography>
                    </li>
                    <li>
                        <Typography>Take initiative and excel in teamwork</Typography>
                    </li>
                </ul>

                <Typography variant='h6' fontWeight='bold' >We would love you to have:</Typography>
                <ul>
                    <li>
                        <Typography>Multi-disciplinary and versatile with the aptitude to learn and apply new skills faster</Typography>
                    </li>
                    <li>
                        <Typography>Good understanding and coding knowledge in these languages: HTML, CSS, JavaScript, Python</Typography>

                    </li>
                    <li>
                        <Typography>Ability to learn new technologies and processes and quickly apply them to the overall development effort</Typography>
                    </li>
                    <li>
                        <Typography>Good analytical and problem-solving skills and proficient in verbal and communication skills</Typography>
                    </li>
                </ul>

                <Typography variant='h5' fontWeight='bold' style={heading} >ELIGIBILITY CRITERIA</Typography>

                <ul>
                    <li>
                        <Typography>All streams/branches of  B.E/B.Tech/M.E/M.Tech, MCA, and M.Sc.</Typography>
                    </li>
                    <li>
                        <Typography>Available for work from home internship</Typography>
                    </li>
                    <li>
                        <Typography>Has relevant skills and interest</Typography>
                    </li>
                </ul>
            </Stack>
            <Button variant='contained' href="#" target="_blank" style={button} >Apply Now</Button>
        </div>
    )
}

export default InternPython