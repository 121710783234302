import {
  Email,
  FacebookSharp,
  Home,
  Instagram,
  LinkedIn,
  Phone,
  Twitter,
} from "@mui/icons-material";
import { Button, IconButton, Stack, Typography, styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import { BrowserRouter as Router, Link } from "react-router-dom";
import urls from "./important_urls.json";

const LinkButton = styled(Button)({
  justifyContent: "left",
  textTransform: "capitalize",
  color: "black",
  "&:hover": {
    backgroundColor: "white",
    color: "teal",
    transition: "0.5s",
  },
});

const SocialMediaIconButton = styled(IconButton)({
  color: "black",
  backgroundColor: "rgba(255,255,255,0.2)",
  "&:hover": {
    color: "teal",
    backgroundColor: "white",
    transition: "0.5s",
  },
});

function Footer() {
  const footerHeading = {
    color: "black",
  };

  const underline = {
    width: "20%",
    border: "1px solid red",
    color: "red",
  };

  const links = {
    textDecoration: "none",
    color: "black",
  };

  return (
    <Router>
      <div>
        <Grid container backgroundColor="#006666" py={5}>
          <Grid item width='25%' pl={2} sx={{ '@media screen and (max-width: 1250px)': { width: '50%', pl: '3%' } }}>
            <Stack spacing={1} width='40%'>
              <Typography fontWeight="bold" variant="h5" style={footerHeading}>
                Company
              </Typography>
              <hr style={underline} />
              <LinkButton href="/careers">Careers</LinkButton>
              <LinkButton href="/services">Our Services</LinkButton>
              <LinkButton href="/privacy-policies">Privacy Policy</LinkButton>
              <LinkButton href="\about-us">About Us</LinkButton>
            </Stack>
          </Grid>

          <Grid item width='25%' pl={2} sx={{ '@media screen and (max-width: 1250px)': { width: '50%', pl: '3%' } }}>
            <Stack spacing={1} width='40%'>
              <Typography fontWeight="bold" variant="h5" style={footerHeading}>
                Quick Links
              </Typography>
              <hr style={underline} />
              <LinkButton href="/">Home</LinkButton>
              <LinkButton href="/contact-us">Contact Us</LinkButton>
            </Stack>
          </Grid>

          <Grid item width='25%' pl={2} sx={{ '@media screen and (max-width: 1250px)': { width: '50%', mt: '2%', pl: '3%' } }}>
            <Stack spacing={1}>
              <Typography fontWeight="bold" variant="h5" style={footerHeading}>
                Follow Us
              </Typography>
              <hr style={underline} />
              <Stack direction="row" spacing={2} pt={2}>
                <SocialMediaIconButton target="_blank" href={urls.facebook}>
                  <FacebookSharp />
                </SocialMediaIconButton>
                <SocialMediaIconButton href={urls.twitter}>
                  <Twitter />
                </SocialMediaIconButton>
                <SocialMediaIconButton target="_blank" href={urls.instagram}>
                  <Instagram />
                </SocialMediaIconButton>
                <SocialMediaIconButton target="_blank" href={urls.linkedin}>
                  <LinkedIn />
                </SocialMediaIconButton>
              </Stack>
            </Stack>
          </Grid>

          <Grid item width='25%' pl={2} sx={{ '@media screen and (max-width: 1250px)': { width: '50%', mt: '2%', pl: '3%' } }}>
            <Stack spacing={1}>
              <Typography fontWeight="bold" variant="h5" style={footerHeading}>
                Communication
              </Typography>
              <hr style={underline} />
              <Stack direction="row" spacing={1}>
                <Home style={{ color: "black" }} />
                <Link style={links}>
                  21, Basavanpura Main Road, KR Puram, 
                  Bengaluru, Karnataka - 560036
                </Link>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Email style={{ color: "black" }} />
                <Link target="_blank" to={urls.email} style={links}>
                  contact@lueurtech.com
                </Link>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Phone style={{ color: "black" }} />
                <Link style={links}>+91 99027 30881/991</Link>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </div>
    </Router>
  );
}

export default Footer;
