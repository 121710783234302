import { ArrowForward } from "@mui/icons-material";
import { Box, Button, Grid, Typography, styled } from "@mui/material";
import React from "react";
import SimpleImageSlider from "react-simple-image-slider";

const GetStartedButton = styled(Button)({
  background: "white",
  color: "black",
  textTransform: "capitalize",
  border: "2px solid teal",
  borderRadius: "30px",
  padding: "1% 5% 1% 5%",
  marginTop: "1%",
  "&:hover": {
    color: "white",
    background: "teal",
    boxShadow: "2px 5px 10px grey",
    transition: "0.5s",
  },
});


function StartCareer() {
  const sliderImages = [
    {
      url: "/assets/head2b.jpg",
    },
    // {
    //     url: '/assets/slide2.jpg'
    // },
    // {
    //     url: '/assets/slide3.jpg'
    // }
  ];

  return (
    <div>
      <Grid container
        width="100%"
        mb="1%"
        mt='3%'
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item zIndex="1" display='flex' alignItems='center' width="50%" my="2.5%" sx={{ '@media screen and (max-width: 1250px)': { width: '80%', mb: '-65%' } }} >
          <Grid
            padding="5% 6% 3% 6%"
            style={{
              backgroundColor: "white",
              boxShadow: "3px 3px 15px 3px lightgrey",
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              Are you willing to build your career with in new technologies?
            </Typography>
            {/* <Typography mb={1} variant='h4' fontWeight='bold'>new technologies?</Typography> */}
            <Typography my={1} variant="h6">
              Start your journey with us.
            </Typography>
            <Box style={{ justifyContent: "right", display: "flex" }}>
              <GetStartedButton href="/careers" style={{ marginTop: "5%" }}>
                Get Started
                <ArrowForward style={{ marginLeft: "10px" }} />
              </GetStartedButton>
            </Box>
          </Grid>
        </Grid>
        <Grid item zIndex="0" width='50%' ml='-6%' sx={{ '@media screen and (max-width: 1250px)': { width: '100%', ml: ' 0', paddingLeft: '4%', paddingRight: '4%', mb: '10%' } }}>
          {/* <SimpleImageSlider
            width="600px"
            height="400px"
            images={sliderImages}
          /> */}
          <img src='./assets/head2b.jpg' height='400px' width='100%' style={{ objectFit: 'cover' }} />
        </Grid>
      </Grid>
    </div>
  );
}

export default StartCareer;
