import { ArrowForward, ArrowForwardIos, ArrowRight } from "@mui/icons-material";
import { Box, Button, Stack, Typography, styled } from "@mui/material";
import React, { useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";

const EnquiryButton = styled(Button)({
  background: "linear-gradient(to right, white, teal)",
  color: "black",
  fontWeight: "bold",
  "&:hover": {
    paddingRight: "20px",
    transition: "0.5s",
  },
});

function HomeTop() {
  const heading = {
    color: "#1aa8d6",
    backgroundImage: `url('./assets/head4.jpg')`,
    backgroundSize: "100%",
    height: "500px",
    display: "flex",
    alignItems: "center",
    marginBottom: "1%",
  };

  const headingText = {
    borderLeftWidth: "10px",
    borderLeftStyle: "solid",
    borderImage: "linear-gradient(#1aa8d6, black)1",
    marginBottom: "2%",
    paddingLeft: "4%",
    width: "100%",
  };

  const [isHovering, setIsHovering] = useState(false);

  const enquiryText = {
    paddingRight: isHovering ? "20px" : "0px",
    transition: "0.5s",
  };

  const arrowIcon = {
    display: isHovering ? "flex" : "none",
    transition: "0.5s",
    color: "white",
  };

  function handleEnter() {
    setIsHovering(true);
  }
  function handleLeave() {
    setIsHovering(false);
  }

  return (
    <div >
      <div style={heading}>
        <div>
          <Stack ml={20} style={headingText} sx={{ '@media screen and (max-width: 1250px)': { ml: '10%' } }}>
            <Typography variant="h2" fontFamily="inherit">
              Transforming Technology,
            </Typography>
            <Typography variant="h2" fontFamily="inherit">
              Transforming Lives
            </Typography>
            {/* <Typography variant="h2" fontFamily="inherit">
              globe
            </Typography> */}
            <Typography variant="h4" fontWeight="bold" mt={2} color="lightgrey">
              Where ideas take flight
            </Typography>
          </Stack>
          <Box ml={20} sx={{ '@media screen and (max-width: 1250px)': { ml: '10%' } }}>
            <EnquiryButton
              variant="contained"
              onMouseEnter={handleEnter}
              onMouseLeave={handleLeave}
              href="/contact-us"
            >
              <Typography
                textTransform="capitalize"
                fontWeight="bold"
                fontSize={15}
                style={enquiryText}
              >
                Click for Enquiry
              </Typography>
              <ArrowForwardIos style={arrowIcon} />
            </EnquiryButton>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default HomeTop;
